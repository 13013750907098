import { account, creditRangea, flowBalanceList,getExport } from "./api.js";

import Util from "@/utils/utils";

export default {
  name: "capitaFlow",
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/financialModule/capitaFlow",
          name: "capitaFlow",
          title: "资金流水"
        }
      ],
      aCherk: 0,
      pageLoadFlag: false,
      ssearchList: {
        yearMonth: "",
        voucher: "", // 交易凭证
        mdmCode: "", // 账户
        businessType: "", // 业务类型
        orgCode: "",// 销售组织
        creditRange: "", // 信贷id
        djFlag: "",
        inOutType: ""
      },

      accountList: [], // 账户列表
      BusinessList: [],//业务类型
      saleList: [], //销售组织

      xdList: [], // 信贷范围
      tableDta: [],
      columns: [
        {
          label: "创建时间",
          prop: "orderDateStr",
          sorter: true,
          sortName: 'orderDate',
          sortable: "orderDate",
          width: 220,
        },
        {
          label: "收支类型",
          prop: "inOutTypeName",
          width: 220
        },
        {
          label: "交易凭证",
          prop: "voucher",
          width: 240
        },
        {
          label: "业务类型",
          prop: "businessTypeName",
          width: 220
        },
        {
          label: "金额变动",
          prop: "orderFund",
          slot: "text",
          align:'right',
          width: 220,
        },
        {
          label: "账户余额",
          prop: "endBalance",
          width: 230,
          align:'right'
        },
        {
          label: "操作",
          prop: "",
          width: 207,
          slot: "btnGroup",
          fixed: 'right',
        }
      ],
      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 0
      },
      showLoading: false,
      tabActive: 0,
      onlyHezuoA: true, // 仅查看合作关系
      balanceInfo: {}, // 表格数据
      isLoading:false
    };
  },
  beforeCreate() {
    // 添加背景色
    document.querySelector("body").setAttribute("style", "background-color:#ffffff");

  },
  beforeDestroy() {
    document.querySelector("body").setAttribute("style", "");
  },
  mounted() {
    this.getCust();


  },

  methods: {
    // 点击排序
    onTableChange(sorter) {
      // debugger
      const sortName = this.columns.filter(it => it.prop == sorter.prop)
      if (sortName.length < 1) {
        return
      }
      if (sorter && sorter.order) {
        this.ssearchList.sortOrder =
          sorter.order == "ascending" ?
            "asc" :
            sorter.order == "descending" ?
              "desc" :
              "";
        this.ssearchList.sortName = sortName[0].sortName;
      } else {
        this.ssearchList.sortOrder = "";
        // this.ssearchList.sortName = "";
      }
      this.getlist();
    },
    // 导出
    exportClick() {
      let data = {
        ...this.ssearchList
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "资金流水.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    // 跳转详情
    publish(record) {
      let routeUrl = this.$router.resolve({
        path: "/order/detail",
        query: {
          id: record.orderId
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    //点击全部支出收入按钮
    clicktab(num) {
      this.tabActive = num;
      // 点击对应的传参不同
      if (num == 0) {
        this.ssearchList.inOutType = "";
        this.getlist();
      }
      if (num == 1) {
        this.ssearchList.inOutType = 0;
        this.getlist();
      }
      if (num == 2) {
        this.ssearchList.inOutType = 1;
        this.getlist();
      }
    },
    //重置
    reset() {
        this.ssearchList.mdmCode = this.accountList[0].code,
        this.ssearchList.orgCode = this.saleList[0].code,
        this.ssearchList.yearMonth = this.fun_date(),
        this.ssearchList.djFlag = "仅合作",
        this.getcreditRange(),
        this.ssearchList.voucher = "",
        this.ssearchList.businessType = "",
        this.ssearchList.djFlag = "";
        this.pager.pageNo = 1;
        this.aCherk = 0;
    },
    searchList() {
      this.pager.pageNo = 1
      this.pager.pageSize = 10
      this.getlist();
    },
    getlist() {

      let data = {
        ...this.ssearchList,
        pageSize: this.pager.pageSize,
        pageNo: this.pager.pageNo

      };
      this.showLoading = true;
      flowBalanceList(data).then(res => {
        if (res.data.code == 0) {
          this.tableDta = res.data.list;
          this.balanceInfo = res.data.balanceInfo;
          this.pager.count = res.data.totalRows;
        } else {
          this.$message.warning("获取数据错误," + res.data.msg);
        }
        this.showLoading = false;
      }).catch(error => {
        console.log(error);
      });
    },
    // 更改多选框按钮
    onlyHezuo(e) {

      this.onlyHezuoA = e.target.checked;
      if (this.onlyHezuoA == true) {
        this.ssearchList.djFlag = "1";
      } else {
        this.ssearchList.djFlag = "全部";
      }
      //更改选中按钮重新调用接口
      this.getlist();
    },
    // 获取信贷范围
    getcreditRange() {
      let data = {
        mdmCode: this.ssearchList.mdmCode,
        orgCode: this.ssearchList.orgCode,
        djFlag: this.ssearchList.djFlag
      };
      creditRangea(data).then(res => {
        this.xdList = res.data.list;
        this.$nextTick(() => {
          if (this.xdList.length > 0) {
            this.ssearchList.creditRange = this.xdList[0].code;
          }

          this.getlist();
        });
      });

    },
    // 筛选条件的选择账户
    getCust() {
      let data = {};
      account(data).then(res => {
        if (res.data.code == 0) {
          this.accountList = res.data.data.custDict;
          this.BusinessList = res.data.data.businessTypeDict;
          this.saleList = res.data.data.orgDict;

        } else {
          this.$message.warning(res.dta.msg);
        }
        this.$nextTick(() => {
          this.ssearchList.mdmCode = this.accountList[0].code;
          this.ssearchList.orgCode = this.saleList[0].code;
          this.ssearchList.yearMonth = this.fun_date();
          this.ssearchList.djFlag = "仅合作";
          this.getcreditRange();
        });
      });
    },
    showModal() {

    },
    // 更改月份
    handleTimeChange(e, defaultTime) {
      this.ssearchList.yearMonth = defaultTime
    },
    // 点击月份
    clickMonth(num) {
      this.aCherk = num;
      if (num !== 0) {
        // this.getMonth(num);
        this.ssearchList.yearMonth = this.getMonth(num);

      } else {
        this.ssearchList.yearMonth = this.fun_date();
      }
      this.pager.pageNo = 1
      this.pager.pageSize = 10
      this.getlist()
      // this.ssearchList.yearMonth =  this.getMonth(num);
    },
    // 获取当前时间
    fun_date() {
      var _this = this;
      let yy = new Date().getFullYear();
      let mm =  (new Date().getMonth()+ 1) < 10 ? "0" + (new Date().getMonth()+ 1) : new Date().getMonth()+ 1;
      let dd = new Date().getDate();
      let hh = new Date().getHours();
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
      let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
      return yy + "-" + mm;

    },
    //获取上月上上月
    getMonth(num) {
      if (num <= 0) return;
      let today = new Date();
      let year = today.getFullYear();//当前年份
      let need_year = year - Math.floor(num / 12);//获取到想要查询月所在的年份
      let need_year_next = need_year;//获取到想要查询月下一个月所在的年份
      let month = today.getMonth() + 1;//获取到当前月

      //获取num 的余数  如果余数大于等于当前月份  则年份还要减1
      let num_remainder = num % 12;
      if (num_remainder >= month) {
        need_year--;
        need_year_next--;
        month += 12;
      }

      //获取到所需要的月份和所需要的月份的下一个月
      let need_month = month - num_remainder;
      let need_month_next = need_month + 1;

      //如果所需要月份的下一个月大于12 则需要调到下一年的一月
      if (need_month_next > 12) {
        need_month_next = 1;
        need_year_next++;
      }
      if (need_month === 0) need_month = 12;
      //所需月份小于10 则前面补0
      if (need_month < 10) need_month = "0" + need_month;
      if (need_month_next < 10) need_month_next = "0" + need_month_next;
      return `${need_year}-${need_month}`;
    },

    // 更改账户
    handleChangeAccontList(e) {

    },
    // 更改业务类型
    handleChangeBusinessType() {

    },
    // 更改信贷
    handleChangeXD(e) {


    },
    // 更改销售组织
    handleChangeorgId(e) {

      this.xdList = [];
      this.ssearchList.creditRange = "";
      this.getcreditRange();
    },
    // getXD() {
    //
    //   this.getcreditRange(data);
    // },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getlist();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};