import http from "@/utils/request";

// post
export function account(data) {
    return http({
        method: "post",
        url: "/balance/flowBalanceParam.nd",
        data
    });
}

// 信贷范围
export function creditRangea(data) {
    return http({
        method: "post",
        url: "/balance/creditRange.nd",
        data
    });
}

// 列表接口

export function flowBalanceList(data) {
    return http({
        method: "post",
        url: "/balance/flowBalance.nd",
        data
    });
}

export function getExport(data) {
    return http({
        url: '/balance/exportFlow.nd',
        method: 'post',
        // contentType: "application/json;charset=UTF-8",
        // type: 'stringfy',
        data:data,
        responseType: 'blob'
    })
}